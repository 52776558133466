<template>
  <div>
    <div class="settingPage">
        <SideBar ref="sidebar" :is-condensed="isMenuCondensed" />
        <NavBar />
        <div class="main-content" id="main-page">
          <div class="page-content" id="content-pages">
            <!-- <div class="container-fluid" id="content-pages"> -->
            <div class="setting-page" id="setting-pages">
            <p class="title-setting-v3">Settings</p>
            <b-tabs class="tabs-setting" active-nav-item-class="active-tabs">
                <b-tab title="Account">
                <div class="setting-content">
                    <p class="sub-title-content-setting">Profile</p>
                    <form action="" @submit.prevent="submit()">
                    <div class="d-flex form-profile">
                        <label for="email-profile" class="mb-4 title-modal-change-password">Email</label>
                        <input
                        disabled
                        v-model="newEmail"
                        type="text"
                        id="email-profile"
                        class="form-control input-profile-v3">
                    </div>
                    <div class="d-flex form-profile">
                        <label for="first-name-profile" class="mb-4 title-modal-change-password">First Name</label>
                        <div class="input-state-profile">
                        <input
                            @focus="focusFirstName()"
                            @focusout="focusoutFirstName()"
                            v-model.trim="$v.newFirstName.$model"
                            type="text"
                            id="first-name-profile"
                            :class="{'is-invalid': validationStatus($v.newFirstName)}"
                            class="form-control input-profile-v3">
                        <div class="invalid-feedback">
                            <span v-if="!$v.newFirstName.maxLength" id="first-name-error" class="required-color error-message-v3">First name max. length is 100 characters.</span>
                            <span v-if="!$v.newFirstName.required" id="first-name-empty" class="required-color error-message-v3">First name cannot be empty.</span>
                        </div>
                        </div>
                    </div>
                    <div class="d-flex form-profile">
                        <label for="last-name-profile" class="mb-4 title-modal-change-password">Last Name</label>
                        <div class="input-state-profile">
                        <input
                            @focus="focusLastName()"
                            @focusout="focusoutLastName()"
                            v-model.trim="$v.newLastName.$model"
                            type="text"
                            id="last-name-profile"
                            :class="{'is-invalid': validationStatus($v.newLastName)}"
                            class="form-control input-profile-v3">
                        <div class="invalid-feedback">
                            <span v-if="!$v.newLastName.maxLength" id="last-name-error" class="required-color error-message-v3">Last name max. length is 100 characters.</span>
                            <span v-if="!$v.newLastName.required" id="last-name-empty" class="required-color error-message-v3">Last name cannot be empty.</span>
                        </div>
                        </div>
                    </div>
                    </form>
                    <div class="btn-edit-profile-v3 mb-3">
                      <button class="btn-submit-edit-profile-v3 black-ink"
                        @click="submit()"
                        :disabled="$v.$invalid || 
                        (isFirstNameFocus == false && isLastNameFocus == false) &&
                        firstname == newFirstName && lastname == newLastName
                        ">Save</button>
                    </div>
                    <hr class="horizontal-account-profile">
                    <p class="sub-title-content-setting">Security</p>
                    <div class="security-profile-v3 mb-3">
                    <div>
                        <p class="title-modal-change-password">Password</p>
                    </div>
                    <div class="mr-change-password-v3">
                        <button class="btn-change-password-v3 black-ink" v-b-modal.modal-change-password-v3>Change Password</button>
                    </div>
                    </div>
                    <div class="security-profile-v3 mb-3">
                    <div>
                        <p class="title-modal-change-password">Two-factor auth (2FA)</p>
                    </div>
                    <div v-if="status2fa == false" class="mr-2fa-enable-v3">
                        <button class="btn-enable-2fa-v3 text-white" @click="enableMFA()">Enable</button>
                    </div>
                    <div v-if="status2fa == true" class="mr-2fa-disable-v3">
                        <button class="btn-disable-2fa-v3 black-ink" @click="disableMFA()">Disable</button>
                    </div>
                    </div>
                    <div v-if="status2fa == true" class="security-profile-v3 mb-3">
                    <div>
                        <p class="title-modal-change-password">Backup codes</p>
                    </div>
                    <div class="mr-backup-codes-v3">
                        <button class="btn-change-password-v3 black-ink" v-b-modal.modal-view-backup-2fa>View</button>
                    </div>
                    </div>
                    <hr class="horizontal-account-profile">
                    <p class="sub-title-content-setting">Delete account</p>
                    <div class="security-profile-v3 mb-2">
                    <div>
                        <p class="title-modal-change-password">Delete your account</p>
                    </div>
                    <div class="mr-delete-account-v3">
                        <button class="btn-delete-account-v3 black-ink" v-b-modal.modal-delete-account-v3>Delete Account</button>
                    </div>
                    </div>
                </div>
                </b-tab>
                <b-tab title="Plan">
                <div class="setting-content">
                    <div class="top-plan-info">
                      <div class="row">
                          <div class="col-md-6 padding-right-16">
                          <div class="box-settings-plan">
                              <div v-if="listPaymentHistory.length >= 1" class="d-flex justify-content-between mb-3">
                              <span class="text-setting-v3">{{ listPaymentHistory ? titleCase(listPaymentHistory[0].package.name_package) : '' }}</span>
                              <span v-if="statusSubs == false" class="amount-text-setting-v3">${{ listPaymentHistory[0].interval == 'month' ? listPaymentHistory[0].package.price_month
                                  : listPaymentHistory[0].package.price_year }}/{{ listPaymentHistory[0].interval }}</span>
                              <span v-else class="amount-text-setting-v3">Canceled</span>
                              </div>
                              <div v-else class="d-flex justify-content-between mb-3">
                              <span class="text-setting-v3">Basic</span>
                              <span class="amount-text-setting-v3">Active</span>
                              </div>
                              <b-progress :value="originalUsed" :max="originalSize"  variant="primary"></b-progress>
                              <div class="d-flex flex-column">
                                <span class="text-setting-v3 mt-2">{{used}} of {{size}} used.</span>
                                <span v-if="listPaymentHistory.length >= 1" class="text-setting-v3">{{ workgroupsList.length }} of {{ listPaymentHistory[0].package.workgroup.max_workgroup }} workgroups created</span>
                                <span v-else class="text-setting-v3">{{ workgroupsList.length }} of 5 workgroups created</span>
                              </div>
                              <div class="d-flex justify-content-end">
                              <!-- <button v-if="isPaid" type="button" class="btn-cancel-plan-v3" v-b-modal.modal-cancel-plan>Cancel plan</button> -->
                                <button v-if="isApplePay == true" type="button" class="btn-upgrade-v3" v-b-modal.modal-apple-pay>Upgrade</button>
                                <button v-else type="button" class="btn-upgrade-v3" v-b-modal.modalPackages>Upgrade</button>
                              </div>
                          </div>
                          </div>
                          <div class="col-md-6 padding-right-16">
                          <div class="box-settings-plan">
                              <p class="text-setting-v3">Payment Method</p>
                              <div class="box-payment-v3" v-if="isPaid && isApplePay == false">
                                <img src="@/assets/images/icon/visa-logo.svg" alt="">
                                <span class="text-setting-v3 mx-3">{{ cardNumber }}</span>
                                <span class="method-payment-msg">{{ cardExpireDate }}</span>
                              </div>
                              <div class="box-payment-v3" v-if="isApplePay == true">
                                <img src="@/assets/images/icon/Apple-pay-logo.svg" alt="">
                                <span class="text-setting-v3 mx-3">Apple Payment</span>
                              </div>
                              <div v-if="!isPaid">
                                <span class="sub-title-empty-history">You’re not set your payment method yet.</span>
                              </div>
                              <div class="d-flex justify-content-end">
                                <button type="button" :disabled="!isPaid || isApplePay == true" class="btn-payment-method-v3" @click="setPayment()">Manage</button>
                              </div>
                          </div>
                          <b-progress :value="originalUsed" :max="originalSize"  variant="primary"></b-progress>
                          <p class="storage-text-setting-v3 mt-2">{{used}} of {{size}} used.</p>
                          <span v-if="listPaymentHistory.length >= 1" class="text-setting-v3">{{ workgroupsList.length }} of {{ listPaymentHistory[0].package.workgroup.max_workgroup }} workgroups created</span>
                          <span v-else class="text-setting-v3">{{ workgroupsList.length }} of 5 workgroups created</span>
                          <div class="d-flex justify-content-end">
                          <button v-if="isPaid" type="button" class="btn-cancel-plan-v3" v-b-modal.modal-cancel-plan>Cancel plan</button>
                          <button type="button" class="btn-upgrade-v3" @click="getPackages()">Upgrade</button>
                          </div>
                      </div>
                      </div>
                      <div class="col-md-6 padding-right-16">
                      <div class="box-settings-plan">
                        <p class="text-setting-v3">Payment Method</p>
                        <div class="box-payment-v3" v-if="isPaid && isApplePay == false">
                          <img src="@/assets/images/icon/visa-logo.svg" alt="">
                          <span class="text-setting-v3 mx-3">{{ cardNumber }}</span>
                          <span class="method-payment-msg">{{ cardExpireDate }}</span>
                        </div>
                        <div class="box-payment-v3" v-if="isApplePay == true">
                          <img src="@/assets/images/icon/Apple-pay-logo.svg" alt="">
                          <span class="text-setting-v3 mx-3">Apple Payment</span>
                        </div>
                        <div v-if="!isPaid">
                          <span class="sub-title-empty-history">You’re not set your payment method yet.</span>
                        </div>
                        <div class="d-flex justify-content-end">
                          <button type="button" :disabled="!isPaid || isApplePay == true" class="btn-payment-method-v3" @click="setPayment()">Manage</button>
                        </div>
                      </div>
                      </div>
                    </div>
                </div>
                <span v-if="listPaymentHistory.length >= 1" class="note-payment-history">NOTE: We only show up 1 year of payment history.</span>
                </b-tab>
            </b-tabs>
            </div>
            <!-- </div> -->
          </div>
        </div>
        <ModalChangePassword />
        <ModalDeleteAccount />
        <ModalPackages />
        <ModalCancelPlan />
        <ModalCheckout />
        <ModalEnable2FA />
        <ModalDisable2FA />
        <ModalQR2FA />
        <ModalCode2FA />
        <ModalBackupCode2FA />
        <ModalViewBackupCode2FA />
        <ModalApplePay/>
    </div>
  </div>
</template>

<script>
// import Layout from "../../layouts/main"
import NavBar from "@/components/nav-bar";
import SideBar from "@/components/side-bar";
import router from "@/router";

import ModalChangePassword from "../../../components/modalChangePasswordV3.vue"
import ModalPackages from "../../../components/modalPackagesV3.vue"
import ModalCancelPlan from "../../../components/modalCancelPlan.vue"
import ModalCheckout from "../../../components/modalCheckoutV3.vue"
import ModalDeleteAccount from "../../../components/modalDeleteAccount.vue";
import ModalEnable2FA from "../../../components/widgets/enable2FA.vue";
import ModalDisable2FA from "../../../components/widgets/disable2FA.vue";
import ModalQR2FA from "../../../components/widgets/modalQR2FA.vue";
import ModalCode2FA from "../../../components/widgets/modalCode2FA.vue";
import ModalBackupCode2FA from "../../../components/widgets/modalBackupCode2FA.vue";
import ModalViewBackupCode2FA from "../../../components/widgets/modalViewBackupCode.vue";
import ModalApplePay from "../../../components/modalApplePay.vue";
import appConfig from "@/app.config"
import { required, maxLength, email } from 'vuelidate/lib/validators'
import moment from 'moment'

export default {
  page: {
    title: "Setting",
    meta: [{ name: "description", content: appConfig.description }]
  },
  data() {
    return {
      newFirstName: '',
      newLastName: '',
      newEmail: '',
      isPaid: false,
      isMenuCondensed: false,
      isFirstNameFocus: false,
      isLastNameFocus: false
    }
  },
  components: {
    // Layout,
    NavBar,
    SideBar,
    ModalChangePassword,
    ModalDeleteAccount,
    ModalPackages,
    ModalCancelPlan,
    ModalCheckout,
    ModalEnable2FA,
    ModalDisable2FA,
    ModalQR2FA,
    ModalCode2FA,
    ModalBackupCode2FA,
    ModalViewBackupCode2FA,
    ModalApplePay
  },
  validations: {
    newFirstName: {
      required,
      maxLength: maxLength(100)
    },
    newLastName: {
      required,
      maxLength: maxLength(100)
    },
    newEmail: {
      required,
      email,
      isUnique (value) {
        if (value === '') return true
        var email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(email_regex.test(value))
          }, 350 + Math.random()*300)
        })
      }
    },
  },
  computed: {
    isApplePay() {
      return this.$store.getters["user/isApplePay"]
    },
    status2fa(){
      return this.$store.getters['user/enabled2fa']
    },
    firstname(){
      return this.$store.getters['user/firstName'];
    },
    lastname(){
      return this.$store.getters['user/lastName'];
    },
    loading(){
      return this.$store.getters['user/loading'];
    },
    nama() {
      return this.$store.getters['user/firstName']
    },
    email() {
      return this.$store.getters['user/email']
    },
    message() {
      return this.$store.getters['error/message']
    },
    success() {
      return this.$store.getters['error/success']
    },
    error() {
      return this.$store.getters['error/error']
    },
    originalSize(){
      return this.$store.getters['user/size_directory'];
    },
    originalUsed(){
      return this.$store.getters['user/total_used'];
    },
    size(){
      return this.$store.getters['user/total_directory_string']
    },
    used(){
      return this.$store.getters['user/used_directory_string']
    },
    workgroupsList(){
      return this.$store.getters['workgroup/workgroups']
    },
    listPaymentHistory () {
      return this.$store.getters["packages/paymentHistory"]
    },
    payMethod() {
      return this.$store.getters["user/card_payment"]
    },
    cardNumber() {
      var data = this.$store.getters["user/card_payment"]
      return ` •••• •••• •••• ${data.last4}`
    },
    cardExpireDate() {
      var data = this.$store.getters["user/card_payment"]
      var month = data.exp_month
      var year = data.exp_year.toString().substr(-2)
      return `${month}/${year}`
    },
    statusSubs(){
      return this.$store.getters["user/statusSuscribe"]
    }
  },
  mounted() {
    this.reset()
    this.$store.dispatch('user/getLimit')
    .then((res) => {
      if(res[0].isapplepay == true){
        this.isPaid = true
      } else {
        if(res[0].stripe_id){
          this.isPaid = true
        } else {
          this.isPaid = false
        }
      }
    })
    this.$store.dispatch('storage/isStorageFull')
    this.$store.dispatch('storage/statSubs')
  },
  methods: {
    getPackages(){
      if(this.isApplePay == false){
        this.$bvModal.show('modalPackages')
      } else {
        this.$bvModal.show('modal-apple-pay')
      }
    },
    focusFirstName(){
      this.isFirstNameFocus = true
    },
    focusoutFirstName(){
      this.isFirstNameFocus = false
    },
    focusLastName(){
      this.isLastNameFocus = true
    },
    focusoutLastName(){
      this.isLastNameFocus = false
    },
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    enableMFA() {
      this.$bvModal.show('modal-enable-2fa')
    },
    disableMFA() {
      this.$bvModal.show('modal-disable-2fa')
    },
    setPayment() {
      this.$store.dispatch('packages/getPaymentMethod')
    },
    toUpgrade() {
      this.$router.push('/packages')
    },
    validationStatus(validation) {
      return typeof validation != undefined ? validation.$error: false
    },
    reset() {
      this.newFirstName = this.firstname
      this.newLastName = this.lastname
      this.newEmail = this.email
    },
    submit(){
      this.$store.dispatch("user/editPassword",
        {
          firstname: this.newFirstName,
          lastname: this.newLastName,
          email: this.newEmail,
        })
        .then(()=>{
          this.$toasted.show('New profile has been saved.',{ 
            theme: "toasted-primary", 
            position: "bottom-center", 
            duration : 2000
          })
          return true;
        });
    },
    convertDate(timestamp) {
      return moment(timestamp * 1000).format('MMM DD, YYYY')
    },
    titleCase(string){
      return string[0].toUpperCase() + string.slice(1).toLowerCase();
    }
  },
}
</script>

<style scoped>
.page-content {
    padding: 15px 0 0 0;
    position: relative;
    max-width: 100%;
    width: 100%;
    margin-top:90px;
    height: calc(100vh - 90px);
    display: flex;
    /* margin-left: -216px; */
}
.main-content{
  min-height: 100vh;
}
</style>

<style>
.p-40px{
  padding-top: 40px;
}
.setting-page{
    padding-top: 15px;
    max-width: 100vw;
    margin-top: 90px;
    padding: 0px 0 0 24px;
    /* padding: 7px 0 0 230px; */
    overflow-x: hidden;
    position: relative;
    height: calc(100vh - 150px);
}
.title-setting-v3{
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    letter-spacing: -1px;
    color: #262A2C;
}
.text-setting-v3{
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
    color: #262A2C;
    letter-spacing: -1px;
}
.box-payment-v3{
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    height: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
}
.storage-text-setting-v3{
    font-weight: 400;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: -0.5px;
    color: #919394;
}
/* .amount-text-setting-v3{
    font-weight: 400;
    line-height: 16px;
    font-size: 12px;
    color: #00AAFF;
    background: #E9F5FB;
    letter-spacing: -0.5px;
    border-radius: 64px;
    padding: 2px 8px;
    height: 20px;
} */
.tabs-setting{
    margin-left: 0px;
    /* height: 100vh; */
    /* width: 100vw; */
    width: calc(100vw - 243px);
}
.tabs-setting ul{
    border-bottom: 1px solid #E9EAEA;
    margin-left: -25px;
}
.tabs-setting ul li{
    border-bottom: 1px solid #E9EAEA;
    margin-left: 25px;
}
.tabs-setting ul .nav-item{
    border: none;
    background: transparent;
    color: #E9EAEA;
    width: 60px;
}
.tabs-setting ul .nav-item .active {
    color: #262A2C !important;
    border-top: none;
    border-right: none;
    border-left: none;
    width: 32px;
    border-bottom: 4px solid #00AAFF;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.tabs-setting ul li a{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -1px;
    color: #919394 !important;
    padding-left: 0;
    padding-right: 0;
}
.tabs-setting ul li a:hover{
    border: none;
}
.tab-content{
}
.setting-content{
    margin-top: 25px;
    max-height: 70vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}
.top-plan-info{
    width: 100%;
}
.bottom-plan-info{
    margin-top: 16px;
    width: 100%;
}
.padding-right-16{
    padding: 0 16px 0 0!important;
}
.box-settings-plan{
    padding: 16px 16px 16px 16px !important;
    height: 200px;
    /* margin-bottom: 25px; */
    border: 1px solid #e8e8e8;
    border-radius: 8px;
}
.table-payment-history thead{
    background: #F6F6FC;
    border-radius: 4px;
    height: 36px;
}
.table-payment-history thead tr th{
  padding-left: 36px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 20px;
  color: #919394;
  letter-spacing: -1px;
  font-weight: 300;
  border-top: none !important;
  border-bottom: none !important;
}
.table-payment-history tbody{
  height: 56px;
}
.table-payment-history tbody tr td{
  padding-left: 36px;
  padding-top: 20px;
  padding-bottom: 20px;
  vertical-align: middle;
  border-bottom: 1px solid #E9EAEA !important;
}
.text-table-payment{
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: -1px;
  color: #262A2C;
}
.method-payment-msg{
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: -1px;
  color: #919394;
}
.sub-title-empty-history{
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  color: #919394;
  letter-spacing: -1px;
}
.text-status-paid-payment{
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: #009934;
  background: #E5F5EB;
  border-radius: 16px;
  width: 33px;
  padding: 1px 4px;
}
.text-status-failed-payment{
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: #D42020;
  background-color: #FBE9E9;
  border-radius: 16px;
  width: 33px;
  padding: 1px 4px;
}
.text-status-else-payment{
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: #262A2C;
  background-color: #E9EAEA;
  border-radius: 16px;
  width: 33px;
  padding: 1px 4px;
}
.text-detail-payment{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -1px;
  color: #00AAFF;
}
.note-payment-history{
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: #919394;
}
.sub-title-content-setting{
  margin-bottom: 16px;
  font-size: 16px;
  color: #919394;
  line-height: 24px;
  letter-spacing: -1px;
  font-weight: 400;
}
.form-profile{
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin-bottom: 16px;
}
.input-profile-v3{
    width: 312px;
    height: 52px;
    border-radius: 4px;
    padding: 16px;
}
.input-state-profile{
    position: relative;
}
.btn-edit-profile-v3{
    display: flex;
    width: 50%;
    justify-content: flex-end;
}
.btn-submit-edit-profile-v3:hover{
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.btn-submit-edit-profile-v3{
    /* color: #FFFFFF; */
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 24px;
    padding: 4px 16px;
    border-radius: 4px;
    border: 1px solid #E9EAEA;
    background: #FFFFFF;
    height: 40px;
    width: 69px;
}
.btn-submit-edit-profile-v3:disabled{
    border: 1px solid #E9EAEA !important;
    color: #E9EAEA !important;
    background: #FFFFFF !important;
}
.security-profile-v3{
    display: flex;
    justify-content: space-between;
    width: 50%;
}
.black-ink{
  color: #262A2C;
}
.btn-change-password-v3:hover{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.btn-change-password-v3{
    /* color: #FFFFFF; */
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 24px;
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid #E9EAEA;
    background: #FFFFFF;
}
.btn-disable-2fa-v3:hover{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.btn-disable-2fa-v3{
    /* color: #FFFFFF; */
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 24px;
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid #E9EAEA;
    background: #FFFFFF;
}
.btn-enable-2fa-v3:hover{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.btn-enable-2fa-v3{
    /* color: #FFFFFF; */
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 24px;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    background: #00AAFF;
}
.btn-delete-account-v3:hover{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.btn-delete-account-v3{
    /* color: #FFFFFF; */
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 24px;
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid #E9EAEA;
    background: #FFFFFF;
}
.btn-upgrade-v3{
    position: absolute;
    bottom: 16px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 24px;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    background: #00AAFF;
}
.btn-cancel-plan-v3{
    position: absolute;
    bottom: 16px;
    right: 148px;
    color: #00AAFF;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 24px;
    /* padding: 8px 16px; */
    border-radius: 4px;
    border: none;
    background: transparent;
    height: 40px;
    width: 117px;
}
.btn-cancel-plan-v3:focus{
  background: transparent;
}
/* .btn-payment-method-v3{
    position: absolute;
    bottom: 16px;
    color: #00AAFF;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 24px;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    background: transparent;
    height: 40px;
    width: auto;
}
.btn-payment-method-v3:disabled{
    color: #E9EAEA;
}
.btn-payment-method-v3:focus{
  background: none;
} */
.mr-change-password-v3{
    margin-right: 9rem;
}
.mr-2fa-enable-v3{
    margin-right: 14.2rem;
}
.mr-2fa-disable-v3{
    margin-right: 14rem;
}
.mr-backup-codes-v3{
    margin-right: 241px;
}
.mr-delete-account-v3{
    margin-right: 165px;
}
.horizontal-account-profile{
    width: 50%;
    display: flex;
    justify-content: flex-start;
    margin-left: 0;
    margin-top: 32px;
}
@media (max-width: 767px) {
  .setting-page{
    padding: 24px 0 0 24px;
  }
  .tabs-setting{
    width: auto;
  }
  .form-profile{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 16px;
  }
  .btn-edit-profile-v3{
    display: flex;
    justify-content: flex-start;
  }
  .security-profile-v3{
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .horizontal-account-profile{
    width: 100%;
  }
}
</style>